
import React, { useEffect, useState } from "react";
import "./App.css";

const App = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    fetch("https://pipeboss.twetools.com/api/api/read.php")
      .then((response) => response.json())
      .then((data) => {
        //console.log(data.birth_date);
        setPosts(data.id);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return <div>{posts}</div>;
};

export default App;
